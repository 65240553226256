@use "../../styles/colors.scss";

.PersonalProjects {
  color: colors.$defaultTextColor;
}

.PersonalProjects-projects {
  margin: 0 20px;
}

.PersonalProjects h1 {
  text-align: center;
}
