.AboutMe-title {
  text-align: center;
}

.AboutMe-info {
  text-align: left;
  margin-left: 15%;
  margin-right: 15%;
}

@media screen and (max-width: 500px) {
  .AboutMe-info {
    margin-left: 20px;
    margin-right: 20px;
  }
}
