$defaultTextColor: white;
$fadedTextColor: #abb0b7;

$mainBackground: #282c34;
$secondaryBackground: #6f7680;
$secondaryHighlight: #5f6670;
$secondaryBorder: lightgray;
$darkBackground: #181c24;

$linkColor: #61dafb;

$buttonSuccess: rgb(51, 216, 51);
$buttonSuccessBorder: green;
$buttonSuccessActive: green;

$buttonPrimary: rgb(15, 99, 209);
$buttonPrimaryBorder: rgb(14, 50, 128);
$buttonPrimaryActive: rgb(14, 50, 128);

$proficient: green;
$basic: orange;
$familiar: #282c34;
