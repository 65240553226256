@use "../../styles/colors.scss";
@use "../../styles/sizes.scss";
@use "../../styles/button-styles.scss";

.Base64Encoder {
  display: flex;
  justify-content: space-around;
  margin: 0 15%;

  textarea {
    background-color: colors.$secondaryBackground;
    border-color: colors.$secondaryBorder;
    color: colors.$defaultTextColor;
    resize: none;
    width: sizes.$utilTextAreaDesktopWidth;
    height: sizes.$utilTextAreaDesktopHeight;
  }
}

.Base64Encoder-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Base64Encoder-button {
  @include button-styles.default-size;
  @include button-styles.primary;
}

@media screen and (max-width: 1100px) {
  .Base64Encoder {
    margin: 0 20px;
  }
}

@media screen and (max-width: 500px) {
  .Base64Encoder {
    margin: 0 20px;
    flex-direction: column;
    align-items: center;

    textarea {
      width: sizes.$utilTextAreaMobileWidth;
      height: sizes.$utilTextAreaMobileHeight;
    }
  }

  .Base64Encoder-button-container {
    flex-direction: row;
  }
}
