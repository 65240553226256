@use "../../styles/colors.scss";
@use "../../styles/sizes.scss";

.Project {
  border-top: 2px solid colors.$secondaryBorder;
  display: flex;
  flex-direction: column;
  margin: 0 20% 20px;
}

.Project-link {
  margin-top: 10px;
  font-size: sizes.$defaultFontSize;
}

.Project-link a {
  color: colors.$linkColor;
}

.Project-container {
  display: flex;
}

.Project-content {
  margin-left: 20px;
}

.Project-skills-used {
  font-size: sizes.$smallFontSize;
  color: colors.$fadedTextColor;
}

.Project-description {
  margin-top: 10px;
  font-size: sizes.$defaultFontSize;
}

.Project-thumbnail {
  width: 200px;
}

@media screen and (max-width: 1000px) {
  .Project {
    margin: 0 0 20px;
  }
}

@media screen and (max-width: 600px) {
  .Project {
    align-items: center;
  }

  .Project-container {
    flex-direction: column;
    align-items: center;
  }

  .Project-content {
    margin-top: 10px;
  }

  .Project-skills-used {
    font-size: sizes.$defaultFontSize;
    text-align: center;
  }

  .Project-description {
    font-size: sizes.$defaultFontSize;
  }

  .Project-link {
    font-size: sizes.$defaultFontSize;
    text-align: center;
  }
}
