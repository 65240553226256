@use "../../styles/colors.scss";

.Utils {
  min-height: 100vh;
}

.Utils-main {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  color: colors.$defaultTextColor;
}

.Utils-tool-select-container {
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
