@use "../../styles/colors.scss";
@use "../../styles/sizes.scss";

.SkillCard {
  border-radius: 15px;
  background-color: colors.$secondaryBackground;
  padding: 10px;
  margin-bottom: 20px;
  margin-right: 5px;
  width: 45%;
  border: 2px colors.$secondaryBorder solid;
}

.SkillCard-name {
  font-weight: bold;
}

.SkillCard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.SkillCard-proficiency {
  font-size: sizes.$smallFontSize;
  font-style: italic;
  border-radius: sizes.$smallFontSize;
  padding: 5px 10px;
}

.SkillCard-proficiency-proficient {
  background-color: colors.$proficient;
}

.SkillCard-proficiency-basic {
  background-color: colors.$basic;
}

.SkillCard-proficiency-familiar {
  background-color: colors.$familiar;
}

.SkillCard-additional-skill {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SkillCard-additional-skill-name {
  font-size: sizes.$defaultFontSize;
  margin-left: 20px;
}

@media screen and (max-width: 900px) {
  .SkillCard {
    width: 100%;
    margin-right: 0;
  }
}
