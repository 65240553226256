@use "../../styles/colors.scss";

.NavBar {
  background-color: colors.$secondaryBackground;
  padding: 15px 0;
  border-bottom: 2px colors.$secondaryBorder solid;
  position: sticky;
  top: 0;
}

.NavBar-link {
  color: colors.$defaultTextColor;
  font-weight: bold;
  text-decoration: none;
  padding: 10px 20px;
  margin: 5px 0;

  &.active {
    background-color: colors.$secondaryHighlight;
  }
}

.NavBar-collapsible-links {
  display: none;
}

.NavBar-separator {
  border: 1px solid colors.$secondaryHighlight;
  padding: 5px 0;
}

.NavBar-menu-button-container {
  display: none;
}

@media screen and (max-width: 500px) {
  .NavBar {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
  }

  .NavBar-desktop-links {
    display: none;
  }

  .NavBar-collapsible-links {
    display: flex;
    flex-direction: column;

    &.collapsed {
      display: none;
    }
  }

  .NavBar-menu-button-container {
    display: flex;
    justify-content: flex-end;
    padding: 0 20px;
  }
}
