@use "../../styles/colors.scss";
@use "../../styles/sizes.scss";
@use "../../styles/button-styles.scss";

.UuidGenerator {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.UuidGenerator-container {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
}

.UuidGenerator-button {
  @include button-styles.default-size;
  @include button-styles.primary;
}

.UuidGenerator-output {
  font-family: monospace;
  font-size: sizes.$largeFontSize;
  width: 500px;
  height: 30px;
  background-color: colors.$secondaryBackground;
  border: solid 1px colors.$secondaryBorder;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 800px) {
  .UuidGenerator-output {
    width: 400px;
  }
}

@media screen and (max-width: 500px) {
  .UuidGenerator-button {
    margin-bottom: 20px;
  }

  .UuidGenerator-container {
    flex-direction: column;
  }

  .UuidGenerator-output {
    font-size: sizes.$defaultFontSize;
    width: 300px;
  }
}
