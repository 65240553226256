@use "colors.scss";

@mixin default-size {
  padding: 10px 20px;
  border-radius: 10px;
  margin: 5px 10px;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

@mixin success {
  background-color: colors.$buttonSuccess;
  border: 1px solid colors.$buttonSuccessBorder;

  &:active {
    background-color: colors.$buttonSuccessActive;
  }
}

@mixin primary {
  background-color: colors.$buttonPrimary;
  border: 1px solid colors.$buttonPrimaryBorder;

  &:active {
    background-color: colors.$buttonPrimaryActive;
  }
}
