.Skills {
  width: 100%;
}

.Skills-title {
  text-align: center;
}

.Skills-list {
  margin-left: 15%;
  margin-right: 15%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.Skills-section-title {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .Skills-list {
    margin-left: 20px;
    margin-right: 20px;
  }
}
