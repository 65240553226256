@use "../../styles/colors.scss";
@use "../../styles/sizes.scss";

.Home-header {
  background-color: colors.$mainBackground;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: sizes.$defaultFontSize;
  color: colors.$defaultTextColor;
}

.Home-link {
  color: colors.$linkColor;
}
