@use "../../styles/colors.scss";

.ContactCard {
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.ContactCard-image {
  width: 480px;
  border-radius: 50%;
}

.ContactCard-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.ContactCard-linkedin {
  color: colors.$linkColor;
}


@media screen and (max-width: 640px) {
  .ContactCard-image {
    width: 75%;
  }
}
